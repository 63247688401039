import React, { createContext, useContext } from "react"
import { useGetProfileQuery } from "../generated/graphql"
import Loading from "../components/Elements/Loading"

const useCreateContext = () => {
	const { data, loading } = useGetProfileQuery()

	return {
		profile: data?.profile,
		loading,
	}
}
type _ProfileContextData = ReturnType<typeof useCreateContext>
type ProfileContextData = Omit<_ProfileContextData, "profile"> & {
	profile: NonNullable<_ProfileContextData["profile"]>
}
const IdentityContext = createContext<ProfileContextData | null>(null)

export const useIdentityContext = () => {
	const ctx = useContext(IdentityContext)

	if (ctx === null) {
		throw new Error("No IdentityContextProvider available.")
	}

	return ctx
}

export const IdentityContextProvider: React.FC = ({ children }) => {
	const ctx = useCreateContext()

	if (!ctx.profile) {
		return <Loading fullScreen />
	}

	return (
		<IdentityContext.Provider value={ctx as ProfileContextData}>
			{children}
		</IdentityContext.Provider>
	)
}

import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, ButtonProps } from "@chakra-ui/react"
import { WarningIcon } from "@chakra-ui/icons"

const AwaitButton: React.FC<
	{
		onClick?: () => Promise<void>
		promise?: Promise<void>
	} & Omit<ButtonProps, "onClick">
> = ({ onClick: triggerAction, promise, children, ...props }) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const mounted = useRef(false)
	const cancelCb = useRef<(() => void) | null>(null)

	useEffect(() => {
		mounted.current = true

		return () => {
			mounted.current = false
		}
	}, [])

	const handlePromise = useCallback((promise: Promise<void>) => {
		let replaced = false

		setError(false)
		setLoading(true)

		const cleanup = (err: boolean) => {
			console.log("cleanup called", {
				mounted: mounted.current,
				replaced,
			})
			if (!mounted.current || replaced) {
				return
			}

			setLoading(false)
			setError(err)
		}

		;(async () => {
			try {
				await promise
			} catch (e) {
				cleanup(true)

				return
			}

			cleanup(false)
		})()

		return () => {
			replaced = true
			cancelCb.current = null
		}
	}, [])

	useEffect(() => {
		if (cancelCb.current) {
			cancelCb.current()
		}

		if (!promise) {
			return
		}

		cancelCb.current = handlePromise(promise)
	}, [handlePromise, promise])

	const onClick = useCallback(async () => {
		if (loading || !triggerAction) {
			return
		}

		if (cancelCb.current) {
			cancelCb.current()
		}

		cancelCb.current = handlePromise(triggerAction())
	}, [loading, handlePromise, triggerAction])

	return (
		<Button
			isLoading={loading}
			disabled={loading}
			onClick={onClick}
			{...props}
			type={props.type ?? "button"}
			leftIcon={error ? <WarningIcon /> : undefined}
			colorScheme={error ? "red" : "blue"}
		>
			{children}
		</Button>
	)
}

export default AwaitButton

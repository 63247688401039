import React from "react"
import { Box, Container, Flex } from "@chakra-ui/react"
import TopBarProfile from "./TopBarProfile"

type TopBarProps = {}
const TopBar: React.FC<TopBarProps> = () => {
	return (
		<Container maxW="container.xl" paddingY={4}>
			<Flex>
				<Box>Oszillopedia</Box>
				<Box marginLeft="auto">
					<TopBarProfile />
				</Box>
			</Flex>
		</Container>
	)
}

export default TopBar

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const UsersRoute: Route = {
	path: "users",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Users"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateUser"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateUser"))
		},
	],
}

import { Route } from "react-location"
import { LazyRouterElement } from "../../helper/LazyRouterElement"

export const SongsRoute: Route = {
	path: "songs",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Songs")),
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateSong")),
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateSong")),
		},
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const LicensesRoute: Route = {
	path: "licenses",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Licenses"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateLicense"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateLicense"))
		},
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const LocationAddressesRoute: Route = {
	path: "location_addresses",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./LocationAddresses"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateLocationAddress"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateLocationAddress"))
		},
	],
}

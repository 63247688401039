import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const LocationsRoute: Route = {
	path: "locations",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Locations"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateLocation"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateLocation"))
		},
	],
}

import { StrictTypedTypePolicies } from "../generated/apollo-helpers"
import { offsetLimitPagination } from "./helpers/offsetLimitPagination"
import { readDateTime, readOptionalDateTime } from "./helpers/readDateTime"

export const typePolicies: StrictTypedTypePolicies = {
	Article: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Band: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	BandsGig: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	BandsGigsPerson: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	BandsImageReference: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	BandsPerson: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	BandsRelease: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	BandsSong: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Genre: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Gig: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Image: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	ImageReference: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Label: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	License: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Location: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	LocationAddress: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	LocationsPerson: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	LocationsRelease: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	LocationsSong: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Origin: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Person: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	PersonsRelease: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	PersonsSong: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Release: {
		fields: {
			release_date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	ReleaseFormat: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Song: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	User: {},
	Version: {
		fields: {
			created: readDateTime,
		},
	},
	PorpaginasResult_Article: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_BandsGig: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_BandsGigsPerson: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_BandsImageReference: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_BandsPerson: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_BandsRelease: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_BandsSong: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Genre: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Gig: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Image: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Label: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_License: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Location: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_LocationAddress: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_LocationsPerson: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_LocationsRelease: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_LocationsSong: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Origin: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Person: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_PersonsRelease: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_PersonsSong: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Release: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_ReleaseFormat: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Song: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_User: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Band: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
}

import { DateTime } from "luxon"

export const readDateTime = (iso: string | undefined) => {
	if (!iso) {
		return undefined
	}

	return DateTime.fromISO(iso).toLocal()
}

export const readDateTimeUTC = (iso: string | undefined) => {
	if (!iso) {
		return undefined
	}

	return DateTime.fromISO(iso).toUTC()
}

export const readDateTimeArray = (isos: string[] | undefined) => {
	if (!isos) {
		return undefined
	}

	return isos.map((iso) => DateTime.fromISO(iso).toLocal())
}

export const readOptionalDateTime = (iso: string | undefined | null) => {
	if (iso === null) {
		return null
	}

	if (iso === undefined) {
		return undefined
	}

	return DateTime.fromISO(iso).toLocal()
}

import React from "react"
import { Box, Stack } from "@chakra-ui/react"
import { Link } from "react-location"

type NavigationProps = {}

type MenuLink = {
	type: "link"
	to: string
	label: string
}

type MenuParent = {
	type: "parent"
	label: string
	children?: MenuLink[]
}

type MenuEntry = MenuLink | MenuParent

// TODO: move this to external file
const menu: MenuEntry[] = [
	{
		type: "link",
		to: "/origins",
		label: "Orte",
	},
	{
		type: "link",
		to: "/genres",
		label: "Genres",
	},
	{
		type: "link",
		to: "/bands",
		label: "Bands",
	},
	{
		type: "link",
		to: "/releases",
		label: "Releases",
	},
	{
		type: "link",
		to: "/songs",
		label: "Songs",
	},
]

const Navigation: React.FC<NavigationProps> = () => {
	return (
		<Box
			width={300}
			flexShrink={0}
			bg="gray.50"
			marginRight={8}
			paddingY={6}
			borderRadius={10}
			boxShadow="md"
		>
			<Stack
				borderBottom={1}
				borderBottomColor="gray.100"
				borderBottomStyle="solid"
			>
				{menu.map((entry, index) => {
					if (entry.type === "link") {
						return (
							<Box
								paddingX={4}
								paddingY={2}
								_hover={{
									background: "gray.200"
								}}
								as={Link}
								to={entry.to}
								key={`nav-${index}`}
							>
								{entry.label}
							</Box>
						)
					}

					// TODO: implement children
					return null
					// return <Box borderBottom={1} borderBottomColor="gray.100" borderBottomStyle="solid">
					// </Box>
				})}
			</Stack>
		</Box>
	)
}

export default Navigation

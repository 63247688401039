import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const PersonsReleasesRoute: Route = {
	path: "persons_releases",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./PersonsReleases"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreatePersonsRelease"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdatePersonsRelease"))
		},
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const BandsPersonsRoute: Route = {
	path: "bands_persons",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./BandsPersons"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateBandsPerson"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateBandsPerson"))
		},
	],
}

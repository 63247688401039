import { Route } from "react-location"
import Homepage from "./routes/generic/Homepage"
import Login from "./routes/generic/authentication/Login"
import { BandsRoute } from "./routes/Bands/routes"
import { SongsRoute } from "./routes/Songs/routes"
import { ReleasesRoute } from "./routes/Releases/routes"
import { OriginsRoute } from "./routes/Origins/routes";
import { BandsGigsRoute } from "./routes/BandsGigs/routes";
import { BandsGigsPersonsRoute } from "./routes/BandsGigsPersons/routes";
import { BandsPersonsRoute } from "./routes/BandsPersons/routes";
import { BandsReleasesRoute } from "./routes/BandsReleases/routes";
import { BandsSongsRoute } from "./routes/BandsSongs/routes";
import { GigsRoute } from "./routes/Gigs/routes";
import { ImagesRoute } from "./routes/Images/routes";
import { LabelsRoute } from "./routes/Labels/routes";
import { LicensesRoute } from "./routes/Licenses/routes";
import { LocationAddressesRoute } from "./routes/LocationAddresses/routes";
import { LocationsRoute } from "./routes/Locations/routes";
import { LocationsPersonsRoute } from "./routes/LocationsPersons/routes";
import { LocationsReleasesRoute } from "./routes/LocationsReleases/routes";
import { LocationsSongsRoute } from "./routes/LocationsSongs/routes";
import { PersonsRoute } from "./routes/Persons/routes";
import { PersonsReleasesRoute } from "./routes/PersonsReleases/routes";
import { PersonsSongsRoute } from "./routes/PersonsSongs/routes";
import { ReleaseFormatsRoute } from "./routes/ReleaseFormats/routes";
import { UsersRoute } from "./routes/Users/routes";
import { GenresRoute } from "./routes/Genres/routes";

export const routes: Route[] = [
	{
		path: "/",
		element: <Homepage />,
	},
	{
		path: "login",
		element: <Login />,
	},
	BandsRoute,
	BandsGigsRoute,
	BandsGigsPersonsRoute,
	BandsPersonsRoute,
	BandsReleasesRoute,
	BandsSongsRoute,
	GigsRoute,
	GenresRoute,
	ImagesRoute,
	LabelsRoute,
	LicensesRoute,
	LocationAddressesRoute,
	LocationsRoute,
	LocationsPersonsRoute,
	LocationsReleasesRoute,
	LocationsSongsRoute,
	OriginsRoute,
	PersonsRoute,
	PersonsReleasesRoute,
	PersonsSongsRoute,
	ReleaseFormatsRoute,
	ReleasesRoute,
	SongsRoute,
	UsersRoute,
]

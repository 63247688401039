import { FieldPolicy, Reference } from "@apollo/client"

type KeyArgs = FieldPolicy<any>["keyArgs"]

// adjusted version of apollos pagination helper
// original: https://github.com/apollographql/apollo-client/blob/d470c964db46728d8a5dfc63990859c550fa1656/src/utilities/policies/pagination.ts#L25
export function offsetLimitPagination<T = Reference>(
	keyArgs: KeyArgs = false
): FieldPolicy<Array<T | null>> {
	return {
		keyArgs,
		merge(existing, incoming, { args }) {
			if (args && args.offset === undefined && args.limit === undefined) {
				return incoming
			}

			const merged: Array<T | null> = existing ? existing.slice(0) : []
			const start = args?.offset ?? 0

			for (let i = existing?.length ?? 0; i < start; i++) {
				merged[i] = null
			}

			const end = start + incoming.length
			for (let i = start; i < end; ++i) {
				merged[i] = incoming[i - start]
			}

			// fill with null to prevent empty indices from being removed
			// this happens when skipping pages while paginating
			const filled =
				start > 0
					? merged.fill(null, existing?.length ?? 0, start)
					: merged

			if (args?.limit && incoming.length < args?.limit) {
				// length of incoming can only be less than limit on last page.
				// we can cut off any excess
				return filled.slice(0, end)
			}

			return filled
		},

		read(existing) {
			return existing?.slice(0, existing.length)
		},
	}
}

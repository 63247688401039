import React, {
	ComponentProps,
	ComponentType,
	LazyExoticComponent,
} from "react"
import { AsyncElement } from "react-location"

export function LazyRouterElement<T extends ComponentType<any>>(
	factory: () => Promise<{ default: T }>
): AsyncElement {
	return async (props) => {
		const imp = factory()
		const Component = React.lazy(() => imp)

		return <Component {...(props as ComponentProps<typeof Component>)} />
	}
}

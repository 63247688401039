import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const GigsRoute: Route = {
	path: "gigs",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Gigs"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateGig"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateGig"))
		},
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const BandsGigsRoute: Route = {
	path: "bands_gigs",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./BandsGigs"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateBandsGig"))
		}
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const LabelsRoute: Route = {
	path: "labels",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Labels"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateLabel"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateLabel"))
		},
	],
}

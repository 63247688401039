import { ApolloClient } from "@apollo/client/core/ApolloClient"
import { InMemoryCache } from "@apollo/client/cache/inmemory/inMemoryCache"
import { HttpLink } from "@apollo/client/link/http/HttpLink"
import { from } from "@apollo/client"

import { typePolicies } from "./typePolicies"

const httpLink = new HttpLink({
	uri: `${process.env.REACT_APP_API_ENDPOINT}/__graphql`,
	credentials: "include",
})

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache({
		typePolicies,
	}),
	link: from([httpLink]),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "cache-and-network",
		},
	},
	queryDeduplication: false,
})

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const LocationsReleasesRoute: Route = {
	path: "locations_releases",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./LocationsReleases"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateLocationsRelease"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateLocationsRelease"))
		},
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const GenresRoute: Route = {
	path: "genres",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Genres"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateGenre"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateGenre"))
		},
	],
}

import React from "react"
import { ReactLocation, Router } from "react-location"
import { routes } from "./routes"
import { AuthenticationContextProvider } from "./contexts/AuthenticationContext"
import Layout from "./components/Layout/Layout"

function App() {
	return (
		<Router location={location} routes={routes}>
			<AuthenticationContextProvider>
				<Layout />
			</AuthenticationContextProvider>
		</Router>
	)
}

const location = new ReactLocation()

export default App

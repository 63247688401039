import React from "react"
import { Box, Container, HStack } from "@chakra-ui/react"
import Navigation from "../Navigation/Navigation"
import { Outlet } from "react-location"
import TopBar from "./TopBar"

type LayoutProps = {}
const Layout: React.FC<LayoutProps> = () => {
	return (
		<>
			<TopBar />
			<Container maxW="container.xl">
				<HStack align="start">
					<Navigation />
					<Box width="100%">
						<Outlet />
					</Box>
				</HStack>
			</Container>
		</>
	)
}

export default Layout

import React from "react"
import { Box, Center, Spinner } from "@chakra-ui/react"

type LoadingProps = {
	fullScreen?: boolean
}
const Loading: React.FC<LoadingProps> = ({ fullScreen }) => {
	return (
		<Center width="100%" height={fullScreen ? "100vh" : undefined}>
			<Spinner />
		</Center>
	)
}

export default Loading

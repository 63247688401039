export const checkLogin = async () => {
	const result = await fetch(
		`${process.env.REACT_APP_API_ENDPOINT}/users/check`,
		{
			credentials: "include",
			headers: {
				accept: "application/json",
			},
		}
	)

	const data = await result.json()

	return data?.authenticated === true
}

export enum AuthenticationEnum {
	SUCCESS,
	WRONG_USER,
	WRONG_PASSWORD,
	LOCKED_ACCOUNT,
	BLOCKED_ACCOUNT,
}
export const authenticate = async (
	username: string,
	password: string
): Promise<AuthenticationEnum> => {
	const formData = new FormData()
	formData.set("username", username)
	formData.set("password", password)
	formData.set("remember_me", "on")

	const response = await fetch(
		`${process.env.REACT_APP_API_ENDPOINT}/users/login`,
		{
			method: "post",
			body: formData,
			credentials: "include",
			headers: {
				accept: "application/json",
			},
		}
	)

	const login = await response.json()

	if (login?.success === true) {
		return AuthenticationEnum.SUCCESS
	}

	return AuthenticationEnum.WRONG_PASSWORD
}

export const logout = async () => {
	await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/logout`, {
		credentials: "include",
		headers: {
			accept: "application/json",
		},
	})
}

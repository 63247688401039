import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react"
import { Outlet, useLocation, useNavigate } from "react-location"
import {
	checkLogin as _checkLogin,
	authenticate as _authenticate,
	logout as _logout,
	AuthenticationEnum,
} from "../authentication"
import { IdentityContextProvider } from "./IdentityContext"
import Loading from "../components/Elements/Loading"

const useCreateContext = () => {
	const [login, setLogin] = useState<boolean | undefined>(undefined)
	const [status, setStatus] = useState<AuthenticationEnum>()
	const oldLocation = useRef<string | undefined>()

	const running = useRef<boolean>(false)
	const navigate = useNavigate()
	const location = useLocation()

	const logout = useCallback(async () => {
		await _logout()

		setLogin(false)
	}, [])

	const checkLogin = useCallback(async () => {
		if (running.current) {
			return
		}

		running.current = true
		setLogin(await _checkLogin())
		running.current = false
	}, [])

	const authenticate = useCallback(
		async (username: string, password: string) => {
			const authResult = await _authenticate(username, password)

			setStatus(authResult)
			setLogin(authResult === AuthenticationEnum.SUCCESS)
		},
		[]
	)

	useEffect(() => {
		checkLogin()
	}, [checkLogin])

	useEffect(() => {
		if (oldLocation.current) {
			return
		}

		oldLocation.current = location.current.pathname
	}, [location])

	useEffect(() => {
		if (!login) {
			return
		}

		const interval = setInterval(checkLogin, 30000)

		return () => {
			clearInterval(interval)
		}
	}, [login, checkLogin])

	useEffect(() => {
		if (login === undefined) {
			return
		}

		if (login) {
			navigate({ to: oldLocation.current ?? "/", replace: true })
			return
		}

		navigate({ to: "/login", replace: true })
	}, [login, navigate])

	return {
		login,
		logout,
		status,
		authenticate,
	}
}
const AuthenticationContext = createContext<ReturnType<
	typeof useCreateContext
> | null>(null)

export const useAuthenticationContext = () => {
	const ctx = useContext(AuthenticationContext)

	if (ctx === null) {
		throw new Error("No AuthenticationContextProvider available.")
	}

	return ctx
}

export const AuthenticationContextProvider: React.FC = ({ children }) => {
	const ctx = useCreateContext()

	return (
		<AuthenticationContext.Provider value={ctx}>
			{ctx.login === undefined && <Loading fullScreen />}
			{ctx.login === false && <Outlet />}
			{ctx.login && (
				<IdentityContextProvider>{children}</IdentityContextProvider>
			)}
		</AuthenticationContext.Provider>
	)
}

import React from "react"
import {
	Button,
	Menu,
	MenuButton,
	MenuGroup,
	MenuItem,
	MenuList,
} from "@chakra-ui/react"
import { useIdentityContext } from "../../contexts/IdentityContext"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { useAuthenticationContext } from "../../contexts/AuthenticationContext"

type TopBarProfileProps = {}
const TopBarProfile: React.FC<TopBarProfileProps> = () => {
	const { logout } = useAuthenticationContext()
	const { profile } = useIdentityContext()

	return (
		<Menu>
			<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
				{profile.username}
			</MenuButton>
			<MenuList>
				<MenuGroup title="Profil">
					<MenuItem>Mein Account</MenuItem>
					<MenuItem onClick={logout}>Logout</MenuItem>
				</MenuGroup>
			</MenuList>
		</Menu>
	)
}

export default TopBarProfile

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const BandsGigsPersonsRoute: Route = {
	path: "bands_gigs_persons",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./BandsGigsPersons"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateBandsGigsPerson"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateBandsGigsPerson"))
		},
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const BandsReleasesRoute: Route = {
	path: "bands_releases",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./BandsReleases"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateBandsRelease"))
		},
	],
}

import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const ReleaseFormatsRoute: Route = {
	path: "release_formats",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./ReleaseFormats"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateReleaseFormat"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateReleaseFormat"))
		},
	],
}

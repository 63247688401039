import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const LocationsPersonsRoute: Route = {
	path: "locations_persons",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./LocationsPersons"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreateLocationsPerson"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdateLocationsPerson"))
		},
	],
}

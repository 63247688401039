import React, { FormEventHandler, useCallback, useMemo, useState } from "react"
import {
	Box,
	Container,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
} from "@chakra-ui/react"
import AwaitButton from "../../../components/Elements/AwaitButton"
import { useAuthenticationContext } from "../../../contexts/AuthenticationContext"
import { AuthenticationEnum } from "../../../authentication"

const RenderStatus: React.FC<{
	status: AuthenticationEnum | undefined
}> = ({ status }) => {
	const text = useMemo(() => {
		switch (status) {
			case AuthenticationEnum.WRONG_USER:
				return "Dieser Account existiert nicht"
			case AuthenticationEnum.WRONG_PASSWORD:
				return "Das Passwort ist nicht korrekt"
			case AuthenticationEnum.LOCKED_ACCOUNT:
				return "Das Passwort wurde zu oft falsch eingegeben und der Account ist nun gesperrt. Bitte setze über die Passwort-Zurücksetzen-Funktion ein neues Passwort."
			case AuthenticationEnum.BLOCKED_ACCOUNT:
				return "Dieser Account wurde von einem Administrator gesperrt."
			case AuthenticationEnum.SUCCESS:
				return "Anmeldung erfolgreich. Du wirst weitergeleitet!"
		}

		return undefined
	}, [status])

	if (!text) {
		return null
	}

	return <Box>{text}</Box>
}

type LoginProps = {}
const Login: React.FC<LoginProps> = () => {
	const { authenticate, status } = useAuthenticationContext()

	const [promise, setPromise] = useState<Promise<any>>()
	const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		async (event) => {
			event.preventDefault()

			const data = new FormData(event.currentTarget)

			const username = data.get("username") as string | null
			const password = data.get("password") as string | null

			if (!username || !password) {
				// TODO: handle error
				return
			}

			const promise = authenticate(username, password)
			setPromise(promise)

			await promise
		},
		[authenticate]
	)

	return (
		<Container>
			<Stack
				bg="gray.50"
				padding={4}
				marginY={4}
				rounded="md"
				boxShadow="md"
				spacing={4}
			>
				<Heading>Login</Heading>

				<form onSubmit={onSubmit}>
					<Stack spacing={4}>
						<FormControl>
							<FormLabel htmlFor="mail">Benutzername:</FormLabel>
							<Input
								name="username"
								type="text"
								placeholder="susi.sorglos"
								bg="white"
							/>
						</FormControl>
						<FormControl>
							<FormLabel htmlFor="password">Password:</FormLabel>
							<Input
								name="password"
								type="password"
								placeholder="Password"
								bg="white"
							/>
						</FormControl>

						<RenderStatus status={status} />

						<AwaitButton
							marginLeft="auto"
							promise={promise}
							type="submit"
						>
							Anmelden
						</AwaitButton>
					</Stack>
				</form>
			</Stack>
		</Container>
	)
}

export default Login

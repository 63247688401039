import { Route } from "react-location";
import { LazyRouterElement } from "../../helper/LazyRouterElement";

export const PersonsRoute: Route = {
	path: "persons",
	children: [
		{
			path: "/",
			element: LazyRouterElement(() => import("./Persons"))
		},
		{
			path: "create",
			element: LazyRouterElement(() => import("./CreatePerson"))
		},
		{
			path: "edit/:bandId",
			element: LazyRouterElement(() => import("./UpdatePerson"))
		},
	],
}
